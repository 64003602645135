import * as React from "react"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import PersonAdd from "@mui/icons-material/Person"
import Settings from "@mui/icons-material/Settings"
import Logout from "@mui/icons-material/Logout"
import Mail from "@mui/icons-material/Mail"
import { Link } from "gatsby"
import { useGetUserQuery } from "../features/api/authApi"

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { data, isLoading, error } = useGetUserQuery()
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {data && data[0]?.userPhoto ? (
              <Avatar
                sx={{ width: 50, height: 50 }}
                src={data[0]?.userPhoto.url}
                alt={props.user}
              />
            ) : (
              <Avatar sx={{ width: 50, height: 50 }}>
                {props.user?.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem style={{ cursor: "default" }}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          {props.user}
        </MenuItem>
        <MenuItem style={{ cursor: "default" }}>
          <ListItemIcon>
            <Mail fontSize="small" />
          </ListItemIcon>
          {props.email}
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              width: "100%",
            }}
            to="/app/profile"
          >
            Profile
          </Link>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Link
            className="settings_link"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              width: "100%",
            }}
            to={`/app/settings`}
          >
            Settings
          </Link>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <button
            style={{
              background: "none",
              outline: "none",
              fontSize: "inherit",
              padding: 0,
              cursor: "pointer",
            }}
            onClick={() => props.removeToken()}
          >
            Logout
          </button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
